import * as React from "react";
import {
  Wrapper,
  LegalMentionsWrapper,
  RowWrapper,
} from "./HistoireEast.module.css";
import { GrayTitle } from "../../../../css/Title.module.css";
import banner from "../../../../res/histoire/east-mosaic.png"
import banner2 from "../../../../res/histoire/east-mosaic-2.png"

const HistoireEast = () => {
  return (
    
    <div className={Wrapper}>

      <h1 className={GrayTitle} >
        EAST VILLAGE
      </h1>
      <div  style={{alignItems: "center", textAlign: "center", paddingTop: "10px"}}>
        <img src={banner} alt="" style={{width: "100%",}}/>
      </div>
      <div className={LegalMentionsWrapper}>
        <div className={RowWrapper}>
          <div>
            
            <h1>Alphabet City </h1>
            <h1>The artistic radical and underground New York 70/80’s scene</h1>
            <p>Dicton de l'East Village :</p>
            <p>Avenue A, you're Alright</p>
            <p>Avenue B, you're Brave</p>
            <p>Avenue C, you're Crazy</p>
            <p>Avenue D, you're Dead</p>
            <br />
            <p>At the end of the 1970’s, New York was a depressed city, at the verge of bankruptcy and almost entirely at the hand of criminal gangs. In the south of the city, and more particularly in the south of 14th street and in the east of Greenwich Village, the district of East Village attracts numerous artists looking for low cost housing.</p>
            <p>It is in this cultural renewal that the communities of the city meet and that Hip-Hop, the world of art and the rock and punk universe meet each other. This exceptional environment will see a singular artistic scene, that will blend a world with infinite contours. In the mid-1980s, the New York Times declared:</p>

            <p style={{fontStyle: "italic"}}>« The East Village was the most interesting district, and maybe the most exciting of the city, and the most interesting and exciting of the world. It became a recognized district national and international wide, for its ideas and its trends in the matter of counter-culture, as a center of the punk rock culture of the East coast and the New Wave movement. It included the emergence of art galleries and various concert halls, as legendary and world-renowned destinations. »</p>
            <p>In 1981, in New York, the contemporary art scene was booming, with Soho as an epicenter. Art dealers like Leo Castelli, Ileana Sonnabend and Mary Boone have defended artists like David Salle and Julian Schnabel, who the critics qualified as neo-expressionist, whose style seduced a growing art market, ready to pay a great amount of money for such young artists. But a few blocks away - in the East Village - a community of smaller galleries, but no less important ones were also emerging like a kind of conceptual satellite orbiting around the world of art. A district where social life and artistic life were merging, furnishing new ways of expression.</p>
            <br />
            <p>In the 1980s, personalities from every horizon, including artists were flowing to New York to make a career. Because of the weakness of the price of housing and of a housing stock consisting of cheap and dilapidated buildings, the East Village has seen numerous young people moving in looking for new experiences and a welcoming place where life is freer. In the East Village, those artists, writers, musicians, etc have found a safe refuge, a place that allowed them to meet and work in a community of mind. It was almost a village with a very intense district life, where artists were meeting for vernissages, visiting each other in their studio, exchanging on their projects, and occupying alternative places.</p>
            <br />
            <p>An avant-garde New York current — who turned its back to the great traditional lines of the avant-garde — was blooming: the East Village scene which, with its galleries and his local celebrities, was imitating the art market of Soho. The East Village artists were nourishing the hope to be discovered and selling their art pieces.</p>
            <br />
            <p style={{fontStyle: "italic"}}>« The East Village is full of artists. Actually, all the first galleries were first artists. We have attended to each other's vernissage and sent curators and collectors to each other’s exhibitions. Carlo McCormick and Walter Robinson have covered everything in the East Village Eye of Leonard Abrams. We have traveled massively to fairs and exhibitions outside the city. When the Fun Gallery has closed its doors in 1987, someone painted with a spray bomb « The Fun Is Gone » outside the walls of the building. This says everything. » Gracie Mansion Rich Colicchio (51X Gallery who had exhibited Dondi White) adds: « We want to create a system of alternative galleries at 57th Street and SoHo, with a different attitude. »</p>
            <br />
            <p>When Patti Astor opened the first gallery in the district, the East Village was about to become the « new SoHo ». We are in 1981. Friend of underground rappers and punk rockers, graffiti artists, and the most fashionable filmmaker in the city, she chose with her partner Bill Stelling a dilapidated apartment building in the East Village as the emplacement for her new experimental exhibition space. Its name: the FUN Gallery. It has discovered artists from the graffiti movement (Lady Pink and Futura 2000) and the first exhibitions of Jean-Michel Basquiat and Keith Haring.</p>
            <p>Galleries were appearing every week. In a few years, the district has become the epicenter of different aesthetic movements, from the Neo-expressionism and its alternatives (Neo-Geo, Neo-Conceptualism, Commodity Art) to Post-Graffiti: from Punk to no wave and Hip Hop, through poetry and writing. A district where the most unexpected meetings happened, where the East Village scene has introduced a pantheon of great names: Jean-Michel Basquiat, Keith Haring, Dondi White, Futura 2000, Jenny Holzer, Peter Hujar, Jeff Koons , David Wojnarowich, Patti Smith , Blondie , Madonna...</p>
            <p>A district where the most unexpected meetings happened, where the East Village scene has introduced a pantheon of great names: Jean-Michel Basquiat, Keith Haring, Dondi White, Futura 2000, Jenny Holzer, Peter Hujar, Jeff Koons , David Wojnarowich, Patti Smith , Blondie , Madonna...</p>
            <br />
            <p>Thus, the 1980s East Village was a singular moment and place, maybe unique as a genre. A union of art and creative rebellion. It may never have been equaled, and may never be. The renewal of urbanism and the increase of housing prices, but also HIV, provoked the disappearance of this unique and diverse population, galleries, and concert halls at the end of the 1980s.</p>
            <br />
            <p>From that time, there is still a huge legacy. The culture born in New York between the end of the 1970s and the end of the 1980s is still present everywhere (Hip Hop: dance, art, or fashion). This is the time that democratized the way of expression, broke the barriers and the forbidden, and dared the difference.</p>
            <br />
            <div  style={{alignItems: "center",textAlign: "center", paddingTop: "10px"}}>
              <img style={{width: "100%"}} src={banner2} alt=""/>
            </div>
            <br />
            <p style={{fontWeight: "bold"}}>1981-1983</p>
            <p style={{fontWeight: "bold"}}>36 mois qui ont changé New-York by "The New-York Times Style Magazine"</p>
            <br />
            <br />
            <p>JANUARY 30, 1981</p>
            <p>Studio 54 owners Steve Rubell and Ian Schrager are released from prison after serving one year for tax evasion.</p>
            <p>APRIL 1, 1981</p>
            <p>Artist Robert Goldman organizes the “Absurdities” show at his Lower East Side art space, ABC No Rio.</p>
            <p>APRIL 9, 1981</p>
            <p>“Beyond Words,” a Keith Haring-organized show goes on view at the Mudd Club. Afrika Bambaataa D.J.s the opening party.</p>
            <p>MAY 8, 1981</p>
            <p>Sonic Youth play their first gig at Club 57, in the basement of a Polish church on St. Marks Place.</p>
            <p>JUNE 22, 1981</p>
            <p>Mark David Chapman pleads guilty to killing John Lennon.</p>
            <p>JULY 3, 1981</p>
            <p>The New York Times reports on Kaposi’s sarcoma, a rare cancer that has mysteriously surfaced in 41 gay men.</p>
            <p>AUGUST 1, 1981</p>
            <p>MTV goes on the air, playing “Video Killed the Radio Star” by the Buggles as its first song.</p>
            <p>AUGUST 1, 1981</p>
            <p>Fran Lebowitz’s sophomore collection of essays, “Social Studies,” is published, and the age of irony begins.</p>
            <p>DECEMBER 20, 1981</p>
            <p>“Dreamgirls,” the Michael Bennett-directed musical about an R&B group shooting for fame, opens on Broadway.</p>
            <p>JANUARY 1, 1982</p>
            <p>N.Y.C. turns the page on its most crime-ridden year to date, with 637,451 reported felonies in 1981.</p>
            <p>SPRING 1982</p>
            <p>Downtown figure Gracie Mansion opens her Loo Division gallery in the bathroom of her fifth-floor East Village walk-up.</p>
            <p>APRIL 4, 1982</p>
            <p>In response to the president’s threats to cut funding for the arts, PS1 puts on “The Ronald Reagan Show,” featuring work by artists such as Marilyn Minter and Frank Young.</p>
            <p>MAY 1982</p>
            <p>Colorful, punk-inspired designer Betsey Johnson heads uptown, opening her second shop on Columbus Avenue.</p>
            <p>AUGUST 18, 1982</p>
            <p>For the first time, more than 100 million shares are traded in a single day on the New York Stock Exchange.</p>
            <p>SEPTEMBER 8, 1982</p>
            <p>Azzedine Alaïa’s body-conscious leather looks arrive at Bergdorf Goodman.</p>
            <p>OCTOBER 6, 1982</p>
            <p>Madonna’s debut single, “Everybody,” is released by Sire Records.</p>
            <p>WINTER 1982</p>
            <p>Jay McInerney’s first story, “It’s Six A.M., Do You Know Where You Are?” appears in The Paris Review.</p>
            <p>FEBRUARY 14, 1983</p>
            <p>Trump Tower’s pink marble atrium opens at 721-725 Fifth Avenue.</p>
            <p>MARCH 12, 1983</p>
            <p>The Buckwheat assassination sketch airs on “Saturday Night Live,” killing off Eddie Murphy’s most popular recurring character.</p>
            <p>APRIL 30, 1983</p>
            <p>Legendary choreographer and New York City Ballet co-founder George Balanchine dies.</p>
            <p>MAY 1983</p>
            <p>Managed by Russell Simmons, Run-DMC of Hollis, Queens, release their first single, “It’s Like That,” with the B-side “Sucker M.C.’s.”</p>
            <p>AUGUST 1983</p>
            <p>An 18-year-old Brooke Shields appears on the cover of Andy Warhol’s Interview magazine.</p>
            <p>SEPTEMBER 15, 1983</p>
            <p>Graffiti artist Michael Stewart lapses into a coma after allegedly being beaten by New York City Transit police, who had arrested him for tagging a subway station. He dies 13 days later. The officers are acquitted in 1985.</p>
            <p>FALL 1983</p>
            <p>RuPaul performs at East Village gay and drag bar the Pyramid Club for the first time.</p>
            <p>DECEMBER 31, 1983</p>
            <p>N.Y.C. has seen 1,851 AIDS diagnoses and 857 deaths.</p>
            <br />
            <br />
            <p style={{fontWeight: "bold"}}>TÉMOIGNAGES</p>
            <br />
            <br />
            <p>Darryl McDaniels, musician and member of Run-DMC</p>
            <br />
            <p>I lived on the quiet end of Hollis, Queens. The whole neighborhood was a lower-middle class suburban area. Every parent, every grown-up was your mother and father. It was like a village, y’know? You couldn’t do nothing stupid or bad because your friend’s mother was your mother, too.</p>
            <p>The first single we ever made [was in 1983]: “Sucker M.C.’s” was the B-side of “It’s Like That.” “It’s Like That” was a record that was talking about all of the things that was going on in communities, society and also the world. It’s basically the most conscious, relatable record. And then we said, “We gotta do the real hip-hop that we’re actually doing at the block parties and at the house parties and at the park parties.” So we decided to make it all beat — no music, just me and Run [Joseph Simmons, another founding member of Run-DMC] doing the real hardcore, just rhyming on this record. That was “Sucker M.C.’s.”</p>
            <p>I was still living at home. I was just out of high school. I was preparing that summer to start my first semester at St. John’s University. We recorded at a studio called Greene Street Recording Studio on Greene Street between Houston and Prince. I will never forget the day, because I didn’t tell my mother and father that I was going to make a record. I just left the house on a Sunday, went to make the record. And I got in trouble because I didn’t get home until like 1 or 2 in the morning. When me and Run would go to each other’s houses and rap together, we would go into the attic. And when my mother asked me where I was at, I just said, “Oh, I was in the attic.”</p>
            <br />
            <br />
            <br />
            <p>Charlie Ahearn, filmmaker and artist</p>
            <br />
            <p>My fantasy was to rent one of these theaters on 42nd Street and show my movie alongside Mad Monkey Kung Fu. To me it was all an art project. [Wild Style] opened in 1983, [at the Embassy 3] on 47th Street and Broadway. I’d paid high school kids to go on Thursday afternoons after school and hand out fliers. Everyone was very excited. There were lines around the block — the theater starting bumping it up to a second and third screening. This scrappy 16mm movie that people weren’t sure if it was a story movie or a documentary, everybody looked at it and said, “I know that guy!” They weren’t public figures; they were locally known graffiti artists, break-dancers, hip-hop M.C.s or D.J.s.</p>
            <p>I felt like I was having the best entertainment of my life, because people were going up and down the aisle, going, “Loose joints …” People were talking throughout the whole movie going, “Yo, shut up!” It was just like the kung fu movies — they were the same audience. The weekend that it happened, I got a call at 2 o’clock in the morning from the owner of the theater. He goes, “What the hell are you doing to my theater? Someone broke the front glass and stole the poster. They put their names all over the men’s room!” The theater had been trashed. I said, “I’m very happy for you, you’re selling a lot of tickets. Sorry."</p>
            <br />
            <br />
            <br />
            <p>Ann Magnuson, actress and performance artist</p>
            <br />
            <p>My days changed depending on what show I was putting on, what sort of rehearsal schedule I had and how late I’d been up the night before, though I had a rule for myself: Always be back before sunrise. My primary motive back then was to put on a show, and anything that slowed me down from that had to be curtailed. By 1981, I was no longer the manager at [the East Village performance space] Club 57, but I still helped out and performed there a lot. The Ladies Auxiliary of the Lower East Side — sort of a punk rock version of my mother’s Junior League group, which I started with some other girls from the East Village — hosted several events at the club. We had a prom, a debutante ball, a ladies’ wrestling night. In 1981, I suggested a bacchanal — a night of pagan merriment as spring was coming. So in April we held the Rites of Spring Fertility Bacchanal. We made an altar to a llama, and everyone dressed in kind of Greco-Roman outfits — I wore a toga with sequins. Wendy Wild made magic mushroom punch. We also created a percussive orchestra that was all pots and pans and a lot of racket, and that was the debut of the band Pulsallama. It was really like a combination of living theater and installation art, very communal. That’s what happened at Club 57 a lot: We told people, “This is the theme. Come be a part of it.” The doors would open and things would get going by 10, and by midnight it would be raging. In the mornings, usually around 11 a.m., I’d go to one of the coffee shops around the corner on Avenue A. Odessa was one, and Leshko’s was the other. People divided themselves into camps based on which one they favored — I liked Odessa better, but I’d go to both. That’s where you’d run into people, share breakfast.</p>
            <br />
            <br />
            <br />
            <p>Dapper Dan, fashion designer</p>
            <br />
            <p>We used to have a TV with a VCR on at all times, playing MTV and the guys coming up. All the music [in the atelier on 125th Street] came from the VCR. People would bring me their videos to pop in. They’d say, “Yo, Dap, listen to that.” All the hip-hop artists came [to the atelier]; there wasn’t any other place that catered to rappers. Those who set the tone — you had to have an outfit from me. Rapping and fashion were being born at the same time. One time a girl — a teenager, maybe 15, 16 — came in with her dad. She had made a video of herself dancing to LL Cool J. Her father said, “Dap, my daughter, she’s driving me crazy. Could you just call LL so she could just say hi?” Everybody knew I knew all the rappers and everything. So I call LL up and I let her speak to him for a few minutes. And when she gave me the phone back, she went into [hysterics]. LL wasn’t used to this kind of attention. He said, “Dap, what’s she doing?” I said, “She’s gasping for air now!” And I go attend to her. And then he asked me again: “Dap, what’s she doing? What’s she doing now?” She was like [hyperventilating]. I never saw that before — and he’d never experienced that. It was before he was onstage and all that; he was just starting out.</p>
            <br />
            <br />
            <br />
            <p>Stephen Koch, writer</p>
            <br />
            <p>I was living at almost the exact point where the Village meets the East Village, in the Colonnade Row building, right across the street from the Public Theater. I had half of the third floor, which was sort of the high-ceilinged fancy room, though it had long, long since stopped being fancy. My contemporary, the writer Ed [Edmund] White, had the apartment next door. I could wake up in the middle of the night and hear his typewriter going.</p>
            <p>I met Peter Hujar through Susan Sontag. We became sort of like brothers. I’m a child of the Midwestern upper-middle class. Peter was straight out of Damon Runyon and Weegee. We were both rather exotic in each other’s eyes. Peter lived on the absolute margins — he never had anything. I remember thinking, How long can this go on? Peter can’t keep going that way, not in the city that it’s becoming now. Peter was so self-possessed and dignified, I never, ever thought of him as poor, even though he had no money. He’d walk in and be perfectly comfortable with the Duke of Edinburgh.</p>
            <p>I invited him to dinner. The conversation was very interesting. Peter had no small talk at all. Then it got to be a habit. He’d come around maybe at 7 o’clock. We’d eat. I would drink quite a lot of wine. He would maybe drink half a glass of wine. Having had an alcoholic mother, he did not like being around people who drank much. We would sit at this coffee table and listen to records for an hour. Silently. Or with only a few comments in between. That is something I have never done with anyone else. Patsy Cline. Mozart. One of his favorite pieces was the Alto Rhapsody of Brahms, sung by Dame Janet Baker. Sometimes I would read him the week’s work. He had two responses: “Pretty good,” was high, high praise. The other was he wouldn’t say anything, just [shrug] — that meant it was a failure.</p>
            <br />
            <br />
            <br />
            <p>Alanna Heiss, founder of PS1 and the Clocktower Gallery</p>
            <br />
            <p>One evening Kenny Scharf and his whole group — which included Jean-Michel Basquiat, and certainly Keith Haring — were hanging out at the artists’ studios at the Clocktower Gallery [in the former New York Life Insurance Company Building at Leonard Street and Broadway]. They had gotten the idea that it was a very good headquarters. It was on the 13th floor of an old city building, and you had to walk up a whole floor from the 12th, which was the last floor served by an elevator. Certainly at night, few people were there, and few were there on the weekends. So the artists would often have free rein, and would sometimes sneak up to the roof, especially for the Fourth of July and other events like that.</p>
            <p>Then, I didn’t have the kind of formal board that one would today. It was chaired by Brendan Gill, a great theater critic and architecture writer. And we had Tina Chow, who had such unearthly beauty — she would come into a room and just drift. Then we had Woody Allen. The deal with him was he never had to come and he never had to give any money, but he would give his name and a recommendation. And then we had Robert Rauschenberg, a rascal and a great man.</p>
            <p>One night, one horrible July Fourth, I invited the board members up to the roof, and I discovered 40 or 50 people at a party that I think Kenny had organized. They were all drunk and wasted and they were happy. And they were there illegally. It was just shattering, but of course I had to pretend that this was the surprise that I had brought my board to see.</p>
            <br />
          <div>
            <p style={{fontSize: "8px"}}>C.PdB</p>
            <p style={{fontSize: "8px"}}>© GHOST galerie - 2018 - TOUS DROITS RÉSERVÉS | MENTIONS LÉGALES</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default HistoireEast;
