// extracted by mini-css-extract-plugin
export var Bold = "HistoireEast-module--Bold--Tpr6l";
export var ContactLink = "HistoireEast-module--ContactLink--AnC3r";
export var Contactlink = "HistoireEast-module--Contactlink--jYjmD";
export var DevelopmentWrapper = "HistoireEast-module--DevelopmentWrapper--hZdtE";
export var LegalMentionsWrapper = "HistoireEast-module--LegalMentionsWrapper--iP1In";
export var Link = "HistoireEast-module--Link--wUSll";
export var NameValueWrapper = "HistoireEast-module--NameValueWrapper--WDJTN";
export var RowWrapper = "HistoireEast-module--RowWrapper--g-YlF";
export var Wrapper = "HistoireEast-module--Wrapper--8qsPy";
export var link = "HistoireEast-module--link--wzJSg";